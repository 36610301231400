import React, { useEffect, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import "./styles.css";

export default function GoogleMapsWrapper(props) {
  // eslint-disable-next-line react/prop-types
  const { markers, height, zoom } = props;

  const hostname = window.location.hostname;
  const key = process.env.REACT_APP_GOOGLE_MAPS_API || "";

  return (
    <GoogleMaps
      googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + key}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: height ? height : "400px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
      markers={markers}
      zoom={zoom || 14}
    />
  );
}

const GoogleMaps = withScriptjs(
  withGoogleMap((props) => {
    const [selectedCenter, setSelectedCenter] = useState(null);

    const markers = props.markers || [
      {
        lat: 52.30149,
        lng: 4.86866,
        title: "Vastgoed Verhuurmakelaardij",
        text: "Van Heuven Goedhartlaan 13D<br />1181 LE Amstelveen",
      },
    ];
    const defaultProps = {
      center: {
        lat: 52.30149,
        lng: 4.86866,
      },
      zoom: props.zoom,
    };

    useEffect(() => {
      const listener = (e) => {
        if (e.key === "Escape") {
          setSelectedCenter(null);
        }
      };
      window.addEventListener("keydown", listener);
      return () => {
        window.removeEventListener("keydown", listener);
      };
    }, []);

    return (
      <GoogleMap
        zoom={defaultProps.zoom}
        center={{
          lat: defaultProps.center.lat,
          lng: defaultProps.center.lng,
        }}
        defaultOptions={{
          styles: [
            {
              featureType: "administrative",
              elementType: "all",
              stylers: [{ visibility: "simplified" }],
            },
            { featureType: "landscape", elementType: "all", stylers: [{ visibility: "on" }] },
            { featureType: "poi", elementType: "all", stylers: [{ visibility: "off" }] },
            { featureType: "transit", elementType: "all", stylers: [{ visibility: "off" }] },
          ],
        }}
      >
        {markers
          .filter(
            (marker) =>
              marker &&
              marker.hasOwnProperty("lat") &&
              marker.hasOwnProperty("lng") &&
              marker.lat != null &&
              marker.lng != null
          )
          .map((marker, key) => (
            <Marker
              key={marker.lat + key}
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
              onClick={() => {
                setSelectedCenter(marker);
              }}
              className={marker.className}
            />
          ))}

        {selectedCenter && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedCenter(null);
            }}
            position={{
              lat: selectedCenter.lat + 0.001, // add 0.001 so popup is not over marker
              lng: selectedCenter.lng,
            }}
          >
            <div>
              <h3>{selectedCenter.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: selectedCenter.text }} />
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  })
);

// TODO
// function GoogleMaps(props) {
//   const hostname = window.location.hostname;
//
//   const markers = [
//     {
//       lat: 52.30149,
//       lng: 4.86866,
//       text: "Kantoor Mats",
//     },
//   ];
//
//   const defaultProps = {
//     center: {
//       lat: 52.30149,
//       lng: 4.86866,
//     },
//     zoom: 14,
//   };
//
//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: "500px", width: "100%" }}>
//       {/* <GoogleMapReact */}
//       {/*   bootstrapURLKeys={{ */}
//       {/*     key: */}
//       {/*       hostname === "localhost" || hostname === "127.0.0.1" */}
//       {/*         ? "" */}
//       {/*         : process.env.REACT_APP_GOOGLE_MAPS_API, */}
//       {/*   }} */}
//       {/*   defaultCenter={defaultProps.center} */}
//       {/*   defaultZoom={defaultProps.zoom} */}
//       {/*   onGoogleApiLoaded={({ map, maps }) => */}
//       {/*     markers.map(({ lat, lng, text }) => { */}
//       {/*       new maps.Marker({ */}
//       {/*         position: { lat: lat, lng: lng }, */}
//       {/*         map, */}
//       {/*         title: text, */}
//       {/*       }); */}
//       {/*     }) */}
//       {/*   } */}
//       {/* ></GoogleMapReact> */}
//     </div>
//   );
// }
